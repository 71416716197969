import { Ticket } from '@hpx-it/queue-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useLoadingButton } from 'hooks';
import { Dispatch, SetStateAction } from 'react';

import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type UnavailableDialogProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const UnavailableDialog = ({
  ticket,
  setTicket,
  open,
  setOpen,
}: UnavailableDialogProps) => {
  const loadingButtonProps = useLoadingButton(async () => {
    setTicket(await ticket.on('RESIDENT_UNAVAILABLE'));
    setOpen(false);
  });

  const handleUnavailableDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleUnavailableDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Unavailable</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will temporarily remove you from the queue. You will not
            receive a video call from our technicians until you mark yourself as
            available again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUnavailableDialogClose}
            {...getDialogExitStyle()}
          >
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
