import { MetricSpy } from '@hpx-it/mui-wrapper';
import { Ticket } from '@hpx-it/queue-client';
import { metricLogger } from '@hpx-it/react-app';
import { Box, Divider, Typography } from '@mui/material';
import { ClientContext, QueueContext, TimeslotProvider } from 'contexts';
import { useContext, useEffect } from 'react';
import { TermsScreen } from 'screens/TermsScreen';

import { AddTicketTimeslotCard } from './AddTicketTimeslotCard';
import { CompletedTicketCard } from './CompletedTicketCard';
import {
  getDividerStyle,
  getReferenceStyle,
  getScreenTitleStyle,
  getTicketScreenBoxStyle,
} from './style';
import { TicketCard } from './TicketCard';
import { TicketScreenFooter } from './TicketScreenFooter';

export const TicketScreen = ({
  ticket,
  reference,
}: {
  ticket: Ticket;
  reference?: string;
}) => {
  const { shouldShowTermsScreen } = useContext(ClientContext);
  const { setTicket } = useContext(QueueContext);
  const isTicketCompleted = ticket.isCompleted() && !ticket.isLive();

  useEffect(() => {
    (async () => {
      if (!ticket.hasResidentUsedLink()) {
        setTicket(await ticket.setResidentUsedLink());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    (!ticket.hasSmsConsent() || !ticket.hasVideoConsent()) &&
    shouldShowTermsScreen
  ) {
    return (
      <MetricSpy
        metrics={{
          logger: metricLogger,
          prefix: 'TermsScreenForTicket',
          tags: {
            ticket_id: ticket.getTicketId(),
            user_viewable_id: ticket.getUserViewableId(),
            supply_client_id: ticket.getSupplyClientId(),
          },
        }}
      >
        <TermsScreen />
      </MetricSpy>
    );
  }

  return (
    <MetricSpy
      metrics={{
        logger: metricLogger,
        prefix: 'TicketScreen',
        tags: {
          ticket_id: ticket.getTicketId(),
          user_viewable_id: ticket.getUserViewableId(),
          supply_client_id: ticket.getSupplyClientId(),
        },
      }}
    >
      <Box {...getTicketScreenBoxStyle()}>
        <Typography {...getScreenTitleStyle()}>
          Remote Assist Video Appointment
        </Typography>
        {reference && (
          <Typography {...getReferenceStyle()}>ID: {reference}</Typography>
        )}

        <Divider {...getDividerStyle()} />
        <AddTicketAndTicketInfo
          ticket={ticket}
          setTicket={setTicket}
          isTicketCompleted={isTicketCompleted}
        />
        <TicketScreenFooter isTicketCompleted={isTicketCompleted} />
      </Box>
    </MetricSpy>
  );
};

const AddTicketAndTicketInfo = ({
  ticket,
  setTicket,
  isTicketCompleted,
}: {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  isTicketCompleted: boolean;
}) => {
  if (isTicketCompleted) {
    return <CompletedTicketCard />;
  }

  return (
    <>
      {!ticket.hasTimeslot() ? (
        <TimeslotProvider>
          <AddTicketTimeslotCard ticket={ticket} setTicket={setTicket} />
        </TimeslotProvider>
      ) : (
        <TicketCard ticket={ticket} setTicket={setTicket} />
      )}
    </>
  );
};
