import { DateTime } from 'luxon';

export const formatDateTime = (date: Date | string) => {
  let typedDate = date;

  if (!(typedDate instanceof Date)) {
    typedDate = new Date(typedDate);
  }

  return new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(typedDate);
};

export const getFormattedTime = (date: DateTime) => {
  return date.toFormat('h:mma');
};

export const getFormattedDate = (date: Date | string) => {
  let typedDate = date;

  if (!(typedDate instanceof Date)) {
    typedDate = new Date(typedDate);
  }

  return new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
  }).format(typedDate);
};
