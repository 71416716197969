import { DeveloperApi } from '@hpx-it/developer-api-types';
import { logger } from '@hpx-it/react-app';
import { Theme } from '@mui/material';
import { DeveloperApiClientContext } from 'contexts/developerApiClientContext';
import jwtDecode from 'jwt-decode';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { generateTheme } from 'theme';

const { REACT_APP_HPA_CLIENT_ID } = process.env;

type ClientBranding = {
  primaryColor?: string;
  logoURL?: string;
};

type ClientContextProps = {
  loading: boolean;
  branding: ClientBranding;
  shouldShowTermsScreen: boolean;
};

export type ClientProviderProps = {
  setTheme: Dispatch<SetStateAction<Theme>>;
  children: ReactNode;
};

const DEFAULT_CONTEXT: ClientContextProps = {
  loading: true,
  branding: {},
  shouldShowTermsScreen: false,
};

export const ClientContext = createContext<ClientContextProps>(DEFAULT_CONTEXT);

export const ClientProvider = ({ setTheme, children }: ClientProviderProps) => {
  const { getDeveloperApiClient, token } = useContext(
    DeveloperApiClientContext,
  );
  const [branding, setBranding] = useState<ClientBranding>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldShowTermsScreen, setShouldShowTermsScreen] =
    useState<boolean>(false);

  useEffect(() => {
    if (branding.primaryColor) {
      setTheme(generateTheme(branding.primaryColor));
    }
  }, [branding.primaryColor, setTheme]);

  useEffect(() => {
    if (!token) {
      logger.warn('No token found');
      setLoading(false);
      return;
    }

    (async () => {
      try {
        const { client_id }: { client_id: string } = jwtDecode(token);

        if (client_id === REACT_APP_HPA_CLIENT_ID) {
          setShouldShowTermsScreen(true);
        }

        const client = await getDeveloperApiClient().getClientWithSignedLink();

        if (client?.branding) {
          const branding = new DeveloperApi.Clients.Branding(client.branding);
          setBranding((prevBranding) => {
            return {
              ...prevBranding,
              primaryColor: branding.getPaletteColor('primary'),
            };
          });

          const logoAttachmentId = branding.getAttachmentId('logo');
          if (logoAttachmentId) {
            const logo =
              await getDeveloperApiClient().downloadAttachmentWithSignedLink({
                id: logoAttachmentId,
              });

            if (logo) {
              setBranding((prevBranding) => {
                return {
                  ...prevBranding,
                  logoURL: URL.createObjectURL(logo),
                };
              });
            }
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [getDeveloperApiClient, token]);

  return (
    <ClientContext.Provider
      value={{ loading, branding, shouldShowTermsScreen }}
    >
      {children}
    </ClientContext.Provider>
  );
};
