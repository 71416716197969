import { MetricSpy } from '@hpx-it/mui-wrapper';
import { metricLogger } from '@hpx-it/react-app';
import { Box, Grid, Typography } from '@mui/material';
import { DeveloperApiClientContext } from 'contexts';
import { useContext } from 'react';

export const NotFoundScreen = () => {
  const { token } = useContext(DeveloperApiClientContext);

  return (
    <MetricSpy
      metrics={{
        logger: metricLogger,
        prefix: 'NotFoundScreen',
        tags: {
          token,
        },
      }}
    >
      <Grid
        pt={8}
        container
        direction="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          pb={5}
          width={{ xs: '85%', sm: '65%', md: '50%' }}
          textAlign="center"
        >
          <Typography
            fontSize={{ xs: '1.5rem', sm: '1.75rem', md: '2.5rem' }}
            variant="h5"
          >
            No remote assist found. Please check you have the correct link.
          </Typography>
        </Grid>
      </Grid>
      <Box position="fixed" bottom={0} zIndex={-1} width="100vw">
        <img
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
            height: '45vh',
          }}
          src="/static/connecting-screen-house.png"
          alt="house"
          width="100%"
        />
      </Box>
    </MetricSpy>
  );
};
