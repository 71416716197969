import { Box, Button, Divider, SvgIcon } from '@mui/material';
import { ComponentProps } from 'react';

export const getButtonBoxStyle = (): ComponentProps<typeof Box> => ({
  alignItems: 'center',
  sx: {
    marginY: '0.5rem',
  },
});

export const getAllowedTimesButtonStyle = ({
  buttonDisabled,
}: {
  buttonDisabled: boolean;
}): ComponentProps<typeof Button> => ({
  variant: 'outlined',
  color: 'primary',
  sx: {
    height: '100%',
    fontSize: '0.9rem',
    whiteSpace: 'pre-line',
    textTransform: 'none',
    boxShadow: 2,
    ...(!buttonDisabled && { pr: 0.5 }),
  },
});

export const getDividerStyle = (): ComponentProps<typeof Divider> => ({
  sx: {
    width: '60%',
    padding: 0,
    marginX: 'auto',
  },
});

export const getUnfoldIconBoxStyle = (): ComponentProps<typeof Box> => ({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const getUnfoldIconStyle = (): ComponentProps<typeof SvgIcon> => ({
  sx: { ml: 1 },
});
