import { useWindowDimensions } from '@hpx-it/react-app';
import { Box, CircularProgress, Typography } from '@mui/material';

import {
  getCircularProgressStyle,
  getLoadingBoxStyle,
  getLoadingTextStyle,
} from './style';

export const LoadingScreen = () => {
  const { height } = useWindowDimensions();

  return (
    <Box {...getLoadingBoxStyle(height)}>
      <CircularProgress {...getCircularProgressStyle()} />
      <Typography {...getLoadingTextStyle()}>Loading...</Typography>
    </Box>
  );
};
