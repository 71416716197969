import { queue } from '@hpx-it/queue-client';
import { Box, Divider, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { SelectDate } from './SelectDate/SelectDate';
import { SelectTime } from './SelectTime/SelectTime';
import {
  getBottomDividerBoxStyle,
  getCenteredBoxStyle,
  getDateTimeTitleStyle,
  getTimeslotDividerStyle,
} from './style';

type SelectTimeslotProps = {
  timeslots: queue.Availability[];
  dateFormat: string;
  selectedTimeslot: queue.Availability | undefined;
  setSelectedTimeslot: (timeslot: queue.Availability | undefined) => void;
};

const filterTimeslots = (timeslots: queue.Availability[], day: number) => {
  return timeslots.filter(
    (timeslot) =>
      DateTime.fromJSDate(timeslot.start).day === day &&
      DateTime.fromJSDate(timeslot.start) > DateTime.now(),
  );
};

export const SelectTimeslot = ({
  timeslots,
  selectedTimeslot,
  setSelectedTimeslot,
  dateFormat,
}: SelectTimeslotProps) => {
  const [allowedDays, setAllowedDays] = useState<number[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());

  const [filteredTimeslots, setFilteredTimeslots] = useState<
    queue.Availability[]
  >([]);

  useEffect(() => {
    const allowedDays = [
      ...new Set(
        timeslots.map((date) =>
          DateTime.fromJSDate(date.start).startOf('day').toMillis(),
        ),
      ),
    ];
    setAllowedDays(allowedDays);
    if (timeslots && timeslots.length > 0) {
      const initialDate = DateTime.fromJSDate(timeslots[0].start).startOf(
        'day',
      );
      setSelectedDate(initialDate);
    }
  }, [timeslots]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDate(selectedDate);
      const filteredTimeslots = filterTimeslots(timeslots, selectedDate.day);
      setFilteredTimeslots(filteredTimeslots);
      if (filteredTimeslots.length > 0) {
        setSelectedTimeslot(
          filteredTimeslots.find((timeslot) => timeslot.count !== 0),
        );
      } else {
        setSelectedTimeslot(undefined);
      }
    }
  }, [setSelectedDate, selectedDate, timeslots, setSelectedTimeslot]);

  return (
    <>
      <Box {...getCenteredBoxStyle()}>
        <Divider {...getTimeslotDividerStyle()}>
          <Typography {...getDateTimeTitleStyle()}>Date</Typography>
        </Divider>
      </Box>
      <SelectDate
        allowedDays={allowedDays}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        dateFormat={dateFormat}
        availableTimeslots={timeslots}
        setFilteredTimeslots={setFilteredTimeslots}
      />
      <Box {...getCenteredBoxStyle()}>
        <Divider {...getTimeslotDividerStyle()}>
          <Typography {...getDateTimeTitleStyle()}>Time</Typography>
        </Divider>
      </Box>
      <SelectTime
        allowedTimes={filteredTimeslots}
        selectedTimeslot={selectedTimeslot}
        setSelectedTimeslot={setSelectedTimeslot}
      />
      <Box {...getBottomDividerBoxStyle()}>
        <Divider {...getTimeslotDividerStyle()} />
      </Box>
    </>
  );
};
