import { queue } from '@hpx-it/queue-client';
import { Box, Divider, List } from '@mui/material';

import { getDividerStyle } from '../../style';
import {
  getTimeslotDialogListBoxStyle,
  getTimeslotDialogListStyle,
} from './style';
import { TimeslotDialogListItem } from './TimeslotDialogListItem';

type TimeslotDialogListProps = {
  setTimeSelectOpen: (open: boolean) => void;
  setSelectedTimeslot: (timeslot: queue.Availability) => void;
  allowedTimes: queue.Availability[];
  formatTimeslot: (timeslot: queue.Availability) => string;
};

export const TimeslotDialogList = ({
  setTimeSelectOpen,
  setSelectedTimeslot,
  allowedTimes,
  formatTimeslot,
}: TimeslotDialogListProps) => {
  return (
    <Box {...getTimeslotDialogListBoxStyle()}>
      <List {...getTimeslotDialogListStyle()}>
        {allowedTimes.map((timeslot, idx) => (
          <Box key={`allowed-timeslot-box-${idx}`}>
            <TimeslotDialogListItem
              key={`timeslot-list-item-${idx}`}
              setTimeSelectOpen={setTimeSelectOpen}
              setSelectedTimeslot={setSelectedTimeslot}
              timeslot={timeslot}
              formatTimeslot={formatTimeslot}
              available={timeslot.count !== 0}
            />
            <Divider key={`divider-${idx}`} {...getDividerStyle()} />
          </Box>
        ))}
      </List>
    </Box>
  );
};
