import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { getAlertStyle, getSnackbarStyle } from './style';

type TimeslotErrorSnackbarProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const TimeslotErrorSnackbar = ({
  open,
  setOpen,
}: TimeslotErrorSnackbarProps) => {
  const handleClose = (
    _e: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} onClose={handleClose} {...getSnackbarStyle()}>
      <Alert {...getAlertStyle()}>
        Failed to schedule. Please select another time.
      </Alert>
    </Snackbar>
  );
};
