import {
  QueueFacadeFactory,
  Ticket,
  TicketFactory,
  queueBooleanStrategies,
  queueGetNumberStrategies,
  queueSetStrategies,
} from '@hpx-it/queue-client';
import { useInterval } from '@hpx-it/react-app';
import { DeveloperApiClientContext } from 'contexts/developerApiClientContext';
import { RemoteAssistContext } from 'contexts/remoteAssistContext';
import { useTicket } from 'hooks';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const { REACT_APP_TICKET_POLL_MS } = process.env;

type QueueContextProps = {
  ticket: Ticket | undefined;
  setTicket: (ticket: Ticket | undefined) => void;
  loadingTickets: boolean;
  routeToVideoRoom: boolean;
  setRouteToVideoRoom: Dispatch<SetStateAction<boolean>>;
};

export type QueueProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: QueueContextProps = {
  ticket: undefined,
  setTicket: () => {},
  loadingTickets: false,
  routeToVideoRoom: false,
  setRouteToVideoRoom: () => {},
};

export const QueueContext = createContext<QueueContextProps>(DEFAULT_CONTEXT);

export const QueueProvider = ({ children }: QueueProviderProps) => {
  const { getDeveloperApiClient } = useContext(DeveloperApiClientContext);
  const { remoteAssistId, setTermsAccepted } = useContext(RemoteAssistContext);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const [routeToVideoRoom, setRouteToVideoRoom] = useState(false);

  const [ticket, setTicket] = useTicket();

  const queueFacade = useMemo(() => {
    try {
      return new QueueFacadeFactory(
        new TicketFactory({
          isStrategies: queueBooleanStrategies,
          getNumberStrategies: queueGetNumberStrategies,
          setStrategies: queueSetStrategies,
        }),
      ).getQueueFacade(getDeveloperApiClient(), getDeveloperApiClient());
    } catch {}
  }, [getDeveloperApiClient]);

  const refetchTickets = useCallback(async () => {
    if (!queueFacade) {
      return;
    }

    (async () => {
      try {
        const tickets = await queueFacade.getTicketsForRemoteAssist(
          remoteAssistId,
        );

        if (tickets.length > 0) {
          setTicket(tickets[0]);
        }
      } finally {
        setLoadingTickets(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueFacade, remoteAssistId]);

  useEffect(() => {
    (async () => {
      if (ticket) {
        setRouteToVideoRoom(ticket.isLive());
        setTermsAccepted(ticket.hasVideoConsent() && ticket.hasSmsConsent());
      } else {
        setRouteToVideoRoom(false);
        setTermsAccepted(false);
      }
    })();
  }, [setTermsAccepted, ticket]);

  useEffect(() => {
    refetchTickets();
  }, [refetchTickets]);

  useInterval(() => {
    refetchTickets();
  }, Number(REACT_APP_TICKET_POLL_MS));

  return (
    <QueueContext.Provider
      value={{
        ticket,
        setTicket,
        loadingTickets,
        routeToVideoRoom,
        setRouteToVideoRoom,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};
