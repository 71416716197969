import { queue } from '@hpx-it/queue-client';
import { Dialog, DialogTitle } from '@mui/material';

import { getTimeslotDialogStyle, getTimeslotDialogTitleStyle } from './style';
import { TimeslotDialogList } from './TimeslotDialogList/TimeslotDialogList';

type TimeslotDialogProps = {
  timeSelectOpen: boolean;
  setTimeSelectOpen: (open: boolean) => void;
  allowedTimes: queue.Availability[];
  setSelectedTimeslot: (timeslot: queue.Availability) => void;
  formatTimeslot: (timeslot: queue.Availability) => string;
};

export const TimeslotDialog = ({
  timeSelectOpen,
  setTimeSelectOpen,
  allowedTimes,
  setSelectedTimeslot,
  formatTimeslot,
}: TimeslotDialogProps) => {
  return (
    <Dialog
      {...getTimeslotDialogStyle({
        open: timeSelectOpen,
        onClose: () => setTimeSelectOpen(false),
      })}
    >
      <DialogTitle {...getTimeslotDialogTitleStyle()}>Select Time</DialogTitle>
      <TimeslotDialogList
        setTimeSelectOpen={setTimeSelectOpen}
        setSelectedTimeslot={setSelectedTimeslot}
        allowedTimes={allowedTimes}
        formatTimeslot={formatTimeslot}
      />
    </Dialog>
  );
};
