import { Button } from '@hpx-it/mui-wrapper';
import { queue } from '@hpx-it/queue-client';
import { metricLogger } from '@hpx-it/react-app';
import { UnfoldMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { QueueContext } from 'contexts';
import { isUndefined, omitBy } from 'lodash';
import { useContext } from 'react';

import {
  getAllowedTimesButtonStyle,
  getButtonBoxStyle,
  getUnfoldIconBoxStyle,
  getUnfoldIconStyle,
} from './style';

type TimeButtonProps = {
  setTimeSelectOpen: (open: boolean) => void;
  selectedTimeslot: queue.Availability | undefined;
  formatTimeslot: (timeslot: queue.Availability) => string;
};

export const TimeButton = ({
  setTimeSelectOpen,
  selectedTimeslot,
  formatTimeslot,
}: TimeButtonProps) => {
  const { ticket } = useContext(QueueContext);

  const buttonDisabled = selectedTimeslot === undefined;

  return (
    <Box {...getButtonBoxStyle()}>
      <Button
        disabled={buttonDisabled}
        {...getAllowedTimesButtonStyle({
          buttonDisabled,
        })}
        onClick={() => {
          setTimeSelectOpen(true);
        }}
        metrics={{
          logger: metricLogger,
          prefix: 'ViewMoreTimes',
          tags: omitBy(
            {
              ticket_id: ticket?.getTicketId(),
              user_viewable_id: ticket?.getUserViewableId(),
              supply_client_id: ticket?.getSupplyClientId(),
            },
            isUndefined,
          ),
        }}
      >
        {!buttonDisabled
          ? formatTimeslot(selectedTimeslot)
          : 'No timeslots for this date'}
        {!buttonDisabled && (
          <Box {...getUnfoldIconBoxStyle()}>
            <UnfoldMore {...getUnfoldIconStyle()} />
          </Box>
        )}
      </Button>
    </Box>
  );
};
