import { Button } from '@hpx-it/mui-wrapper';
import { metricLogger } from '@hpx-it/react-app';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { QueueContext, RemoteAssistContext } from 'contexts';
import { useLoadingButton } from 'hooks';
import { isUndefined, omitBy } from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';

import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type CancelRemoteAssistDialogProps = {
  source: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const CancelRemoteAssistDialog = ({
  source,
  open,
  setOpen,
}: CancelRemoteAssistDialogProps) => {
  const { ticket } = useContext(QueueContext);
  const { getRemoteAssist, cancelRemoteAssist, remoteAssist } =
    useContext(RemoteAssistContext);

  const loadingButtonProps = useLoadingButton(async () => {
    await cancelRemoteAssist();
    setOpen(false);
    await getRemoteAssist();
  });

  const handleCancelRemoteAssistDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancelRemoteAssistDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Cancel Remote Assist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel your video appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelRemoteAssistDialogClose}
            {...getDialogExitStyle()}
            metrics={{
              logger: metricLogger,
              prefix: 'CancelRemoteAssistExit',
              tags: {
                source,
                remote_assist_id: remoteAssist?.id,
              },
            }}
          >
            Exit
          </Button>
          <Button
            {...getDialogConfirmStyle()}
            {...loadingButtonProps}
            metrics={{
              logger: metricLogger,
              prefix: 'CancelRemoteAssistConfirm',
              tags: omitBy(
                {
                  source,
                  remote_assist_id: remoteAssist?.id,
                  ticket_id: ticket?.getTicketId(),
                  user_viewable_id: ticket?.getUserViewableId(),
                  supply_client_id: ticket?.getSupplyClientId(),
                },
                isUndefined,
              ),
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
