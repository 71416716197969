import { queue } from '@hpx-it/queue-client';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';

import {
  getTimeslotDialogListButtonStyle,
  getTimeslotDialogListItemStyle,
} from './style';

type TimeslotDialogListItemProps = {
  setTimeSelectOpen: (open: boolean) => void;
  setSelectedTimeslot: (timeslot: queue.Availability) => void;
  timeslot: queue.Availability;
  available: boolean;
  formatTimeslot: (timeslot: queue.Availability) => string;
};

export const TimeslotDialogListItem = ({
  setTimeSelectOpen,
  setSelectedTimeslot,
  timeslot,
  available,
  formatTimeslot,
}: TimeslotDialogListItemProps) => {
  return (
    <ListItem {...getTimeslotDialogListItemStyle()}>
      <ListItemButton
        disabled={!available}
        {...getTimeslotDialogListButtonStyle({
          onClick: () => {
            setTimeSelectOpen(false);
            setSelectedTimeslot(timeslot);
          },
        })}
      >
        <ListItemText primary={formatTimeslot(timeslot)} />
      </ListItemButton>
    </ListItem>
  );
};
