import { queue } from '@hpx-it/queue-client';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { getFormattedTime } from '../../../format';
import { TimeButton } from './TimeButton';
import { TimeslotDialog } from './TimeslotDialog/TimeslotDialog';

export type SelectTimeProps = {
  allowedTimes: queue.Availability[];
  selectedTimeslot: queue.Availability | undefined;
  setSelectedTimeslot: (timeslot: queue.Availability | undefined) => void;
  formatTimeslot?: (timeslot: queue.Availability) => string;
};

const defaultFormatTimeslot = (timeslot: queue.Availability) => {
  return `${getFormattedTime(
    DateTime.fromJSDate(timeslot.start),
  )} - ${getFormattedTime(DateTime.fromJSDate(timeslot.end))}`;
};

export const SelectTime = ({
  allowedTimes,
  selectedTimeslot,
  setSelectedTimeslot,
  formatTimeslot = defaultFormatTimeslot,
}: SelectTimeProps) => {
  const [timeSelectOpen, setTimeSelectOpen] = useState<boolean>(false);

  return (
    <>
      <TimeButton
        setTimeSelectOpen={setTimeSelectOpen}
        selectedTimeslot={selectedTimeslot}
        formatTimeslot={formatTimeslot}
      />
      <TimeslotDialog
        timeSelectOpen={timeSelectOpen}
        setTimeSelectOpen={setTimeSelectOpen}
        allowedTimes={allowedTimes}
        setSelectedTimeslot={setSelectedTimeslot}
        formatTimeslot={formatTimeslot}
      />
    </>
  );
};
