import { Alert, Snackbar } from '@mui/material';
import { ComponentProps } from 'react';

export const getSnackbarStyle = (): ComponentProps<typeof Snackbar> => ({
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 6000,
});

export const getAlertStyle = (): ComponentProps<typeof Alert> => ({
  severity: 'warning',
  variant: 'filled',
  sx: {
    width: '100%',
  },
});
