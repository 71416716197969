import { Ticket } from '@hpx-it/queue-client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useLoadingButton } from 'hooks';
import { Dispatch, SetStateAction } from 'react';

import { formatDateTime } from '../format';
import {
  getDialogBoxStyle,
  getDialogConfirmStyle,
  getDialogExitStyle,
} from './style';

type JoinQueueDialogProps = {
  ticket: Ticket;
  setTicket: (ticket: Ticket) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const JoinQueueDialog = ({
  ticket,
  setTicket,
  open,
  setOpen,
}: JoinQueueDialogProps) => {
  const loadingButtonProps = useLoadingButton(async () => {
    setTicket(await ticket.on('JOIN_CALL_NOW'));
    setOpen(false);
  });

  const handleJoinQueueDialogClose = async () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleJoinQueueDialogClose}>
      <Box {...getDialogBoxStyle()}>
        <DialogTitle>Join Queue Now</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Please confirm that you are home and available for a video call within the
            next few hours from ${formatDateTime(new Date())}.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleJoinQueueDialogClose}
            {...getDialogExitStyle()}
          >
            Exit
          </Button>
          <Button {...getDialogConfirmStyle()} {...loadingButtonProps}>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
