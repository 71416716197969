import { Button } from '@hpx-it/mui-wrapper';
import { metricLogger } from '@hpx-it/react-app';
import { UnfoldMore } from '@mui/icons-material';
import { Box } from '@mui/material';
import { QueueContext } from 'contexts';
import { isUndefined, omitBy } from 'lodash';
import { DateTime } from 'luxon';
import { useContext } from 'react';

import {
  getDateButtonBoxStyle,
  getDateButtonStyle,
  getUnfoldIconBoxStyle,
  getUnfoldIconStyle,
} from './style';

type DateButtonProps = {
  selectedDate: DateTime;
  setCalendarOpen: (open: boolean) => void;
  dateFormat: string;
};

export const DateButton = ({
  selectedDate,
  setCalendarOpen,
  dateFormat,
}: DateButtonProps) => {
  const { ticket } = useContext(QueueContext);

  return (
    <Box {...getDateButtonBoxStyle()}>
      <Button
        {...getDateButtonStyle()}
        onClick={() => setCalendarOpen(true)}
        metrics={{
          logger: metricLogger,
          prefix: 'ViewMoreDates',
          tags: omitBy(
            {
              ticket_id: ticket?.getTicketId(),
              user_viewable_id: ticket?.getUserViewableId(),
              supply_client_id: ticket?.getSupplyClientId(),
            },
            isUndefined,
          ),
        }}
      >
        {selectedDate.toFormat(dateFormat)}
        <Box {...getUnfoldIconBoxStyle()}>
          <UnfoldMore {...getUnfoldIconStyle()} />
        </Box>
      </Button>
    </Box>
  );
};
