import { Typography } from '@mui/material';
import { useState } from 'react';

import { CancelRemoteAssistDialog } from './CancelRemoteAssistDialog';
import { getCancelHereStyle, getFooterSentenceStyle } from './style';

type CancelRemoteAssistProps = {
  source: string;
};

export const CancelRemoteAssist = ({ source }: CancelRemoteAssistProps) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Typography {...getFooterSentenceStyle()} component={'span'}>
        To cancel your video appointment,{' '}
        <Typography
          onClick={() => setDialogOpen(true)}
          {...getCancelHereStyle()}
        >
          click here
        </Typography>
        .
      </Typography>
      <CancelRemoteAssistDialog
        source={source}
        open={dialogOpen}
        setOpen={setDialogOpen}
      />
    </>
  );
};
