import {
  DeveloperApiClient,
  getManualAuthDeveloperApiClient,
} from '@hpx-it/developer-api-client';
import { ReactNode, createContext, useCallback, useMemo } from 'react';

import { ManualAuthBaseClient } from './ManualAuthBaseClient';

type DeveloperApiClientContextProps = {
  token?: string;
  getDeveloperApiClient: () => DeveloperApiClient;
};

export type DeveloperApiClientProviderProps = {
  children: ReactNode;
};

const DEFAULT_CONTEXT: DeveloperApiClientContextProps = {
  getDeveloperApiClient: () =>
    getManualAuthDeveloperApiClient(
      process.env.REACT_APP_DEVELOPER_API_URL,
      '',
    ),
};

export const throwDeveloperApiUnauthenticatedError = (): never => {
  throw new Error('Unauthenticated to make request');
};

export const DeveloperApiClientContext =
  createContext<DeveloperApiClientContextProps>(DEFAULT_CONTEXT);

export const DeveloperApiClientProvider = ({
  children,
}: DeveloperApiClientProviderProps) => {
  const searchParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const token = useMemo(
    () => searchParams.get('token') ?? undefined,
    [searchParams],
  );

  const developerApiClient = useMemo(() => {
    if (!token) {
      return undefined;
    }

    return new DeveloperApiClient(
      new ManualAuthBaseClient(process.env.REACT_APP_DEVELOPER_API_URL, token),
    );
  }, [token]);

  const getDeveloperApiClient = useCallback((): DeveloperApiClient => {
    if (!developerApiClient) {
      throwDeveloperApiUnauthenticatedError();
    }

    return developerApiClient as DeveloperApiClient;
  }, [developerApiClient]);

  return (
    <DeveloperApiClientContext.Provider
      value={{
        token,
        getDeveloperApiClient,
      }}
    >
      {children}
    </DeveloperApiClientContext.Provider>
  );
};
